import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import BanListModal from "../../components/BanListModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_BAN_LIST") {
    const banList = action.payload;
    const newBanList = [];

    banList.forEach((ban) => {
      const banListIndex = state.findIndex((b) => b.id === ban.id);
      if (banListIndex !== -1) {
        state[banListIndex] = ban;
      } else {
        newBanList.push(ban);
      }
    });

    return [...state, ...newBanList];
  }

  if (action.type === "UPDATE_BAN_LIST") {
    const banList = action.payload;
    const banListIndex = state.findIndex((b) => b.id === banList.id);

    if (banListIndex !== -1) {
      state[banListIndex] = banList;
      return [...state];
    } else {
      return [banList, ...state];
    }
  }

  if (action.type === "DELETE_BAN_LIST") {
    const banListId = action.payload;

    const banListIndex = state.findIndex((b) => b.id === banListId);
    if (banListIndex !== -1) {
      state.splice(banListIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const BanList = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [banList, dispatch] = useReducer(reducer, []);
  const [selectedBanList, setSelectedBanList] = useState(null);
  const [banListModalOpen, setBanListModalOpen] = useState(false);
  const [deletingBanList, setDeletingBanList] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchBanList = async () => {
        try {
          const { data } = await api.get("/banList/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_BAN_LIST", payload: data.banList });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchBanList();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("banList", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_BAN_LIST", payload: data.banList });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_BAN_LIST",
          payload: +data.banListId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenBanListModal = () => {
    setSelectedBanList(null);
    setBanListModalOpen(true);
  };

  const handleCloseBanListModal = () => {
    setSelectedBanList(null);
    setBanListModalOpen(false);
  };

  const handleEditBanList = (banList) => {
    setSelectedBanList(banList);
    setBanListModalOpen(true);
  };

  const handleDeleteBanList = async (banListId) => {
    try {
      await api.delete(`/banList/${banListId}`);
      toast.success(i18n.t("banList.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingBanList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const confirmDeleteAllBanMessage = () => {
    const confirm = window.confirm(i18n.t("banList.popup.confirm"));
    if (confirm) {
      handleDeleteAllBanList();
    }
  };

  const handleDeleteAllBanList = async () => {
    try {
      await api.delete(`/banListAll`);
      toast.success(i18n.t("banList.toasts.deleted"));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      toastError(err);
    }
    setDeletingBanList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingBanList &&
          `${i18n.t("banList.confirmationModal.deleteTitle")} ${
            deletingBanList.groupId
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteBanList(deletingBanList.id)}
      >
        {i18n.t("banList.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <BanListModal
        open={banListModalOpen}
        onClose={handleCloseBanListModal}
        aria-labelledby="form-dialog-title"
        banListId={selectedBanList && selectedBanList.id}
      ></BanListModal>
      <MainHeader>
        <Title>{i18n.t("banList.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("banList.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenBanListModal}
          >
            {i18n.t("banList.buttons.add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmDeleteAllBanMessage}
          >
            {i18n.t("banList.buttons.delete")}
          </Button>  
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("banList.table.shortcut")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("banList.table.message")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("banList.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {banList.map((ban) => (
                <TableRow key={ban.id}>
                  <TableCell align="center">{ban.groupId === 'all' ? 'Todos os Grupos' : ban.groupId}</TableCell>
                  <TableCell align="center">{ban.user}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditBanList(ban)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingBanList(ban);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default BanList;
