import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

//import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textGroupLinkListContainer: {
    width: "100%",
  },
}));

const GroupLinkListSchema = Yup.object().shape({
  groupId: Yup.string().required("Required"),
  // name: Yup.string().required("Required"),
  // link: Yup.string().required("Required"),
  // participants: Yup.string().required("Required"),
});

const GroupLinkListsModal = ({
  open,
  onClose,
  groupLinkListId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    groupId: "",
    // name: "",
    // link: "",
    // participants: ""
  };

  const [groupLinkList, setGroupLinkList] = useState(initialState);
  const [whatsappId, setWhatsappId] = useState("");
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchGroupLinkList = async () => {
      if (initialValues) {
        setGroupLinkList((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!groupLinkListId) return;

      try {
        const { data } = await api.get(`/groupLinkList/${groupLinkListId}`);
        if (isMounted.current) {
          setGroupLinkList(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchGroupLinkList();
  }, [groupLinkListId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setGroupLinkList(initialState);
  };

  const handleSaveGroupLinkList = async (values) => {
    values.whatsappId = parseInt(whatsappId);
    try {
      if (groupLinkListId) {
        await api.put(`/groupLinkList/${groupLinkListId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/groupLinkList", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("groupLinkList.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const fetchGroupList = async () => {
    const data = {
      whatsappId: parseInt(whatsappId),
    };

    try {
      const response = await api.post(`/zdgSingleListGroup/`, data);
      setGroupList(response.data.groups);
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {groupLinkListId
            ? `${i18n.t("groupLinkListModal.title.edit")}`
            : `${i18n.t("groupLinkListModal.title.add")}`}
        </DialogTitle>
        <Paper className={classes.paper}>
          <TextField
            label={i18n.t("commonLabels.whatsAppID")}
            margin="dense"
            variant="outlined"
            fullWidth
            value={whatsappId}
            onChange={(e) => setWhatsappId(e.target.value)}
          />
        </Paper>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchGroupList}
          style={{ marginRight: '8px' }}
        >
          {i18n.t("commonLabels.action")}
        </Button>
        <Formik
          initialValues={groupLinkList}
          enableReinitialize={true}
          validationSchema={GroupLinkListSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveGroupLinkList(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textGroupLinkListContainer}>
                  <Field
                    as={TextField}
                    select
                    label={i18n.t("groupLinkListModal.form.groupId")}
                    name="groupId"
                    autoFocus
                    error={touched.groupId && Boolean(errors.groupId)}
                    helperText={touched.groupId && errors.groupId}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  >
                    {groupList.map((groupString) => {
                      const [, name, id] = groupString.match(/Nome: (.+) - ID: (.+)/);
                      return (
                        <MenuItem key={id} value={id}>
                          {name} - ID: {id}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </div>
                {/* <div className={classes.textGroupLinkListContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("groupLinkListModal.form.name")}
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    minRows={5}
                    fullWidth
                  />
                </div>
                <div className={classes.textGroupLinkListContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("groupLinkListModal.form.link")}
                    name="link"
                    error={touched.link && Boolean(errors.link)}
                    helperText={touched.link && errors.link}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    minRows={5}
                    fullWidth
                  />
                </div>
                <div className={classes.textGroupLinkListContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("groupLinkListModal.form.participants")}
                    name="participants"
                    error={touched.participants && Boolean(errors.participants)}
                    helperText={touched.participants && errors.participants}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    minRows={5}
                    fullWidth
                  />
                </div> */}
                {/* <div>
                  <Switch
                    checked={!showGroupId}
                    onChange={() => setShowGroupId(!showGroupId)}
                  />
                  <span>{i18n.t("groupLinkListModal.buttons.toggle")}</span>
                </div> */}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("groupLinkListModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {groupLinkListId
                    ? `${i18n.t("groupLinkListModal.buttons.okEdit")}`
                    : `${i18n.t("groupLinkListModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default GroupLinkListsModal;
