import React, { useState, useEffect, useRef, useCallback } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textGhostListContainer: {
    width: "100%",
  },
}));

const GhostModal = ({
  open,
  onClose,
  ghostListId,
  onSave,
  ghostId
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const [body, setBody] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);


  const initialState = {
    body: "",
    ticket: "",
    whatsappId: ""
  };

  const [ghostList, setGhostList] = useState(initialState);
  const [showOptionsInput, setShowOptionsInput] = useState(false); 

  const handleSwitchChange = () => {
    setShowOptionsInput(!showOptionsInput);
  };

  const handleChangeSetting = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/tickets/" + ghostId);
      const payload = {
        groupId: data.contact.number,
        whatsappId: parseInt(data.whatsappId),
      };
      const response = await api.post(`/zdgSingleListParticipants/`, payload);
      setOptions(response.data.participants);
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  }, [ghostId]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (open) {
      handleChangeSetting();
    }
  }, [open, handleChangeSetting]);
  

  const handleClose = () => {
    onClose();
    setGhostList(initialState);
    setSelectedOption([]);
  };

  // const handleSaveGhostList = async () => {
  //   setIsLoading(true);
  //   const { data } = await api.get("/tickets/" + ghostId);
  //   try {
  //     const payload = {
  //       body: body,
  //       ticket: data.contact.number,
  //       whatsappId: data.whatsappId
  //     }
  //     console.log(payload)
  //     const response = await api.post("/messages/mentionMessage/" + ghostId, payload);
  //     if (onSave) {
  //       onSave(response.data);
  //     }
  //     setBody('');
  //     handleClose();
  //     toast.success(i18n.t("mentionModal.success"));
  //   } catch (err) {
  //     toastError(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveMentionParticipant = async () => {
    if (!showOptionsInput){
      setIsLoading(true);
      const { data } = await api.get("/tickets/" + ghostId);
      try {
        const payload = {
          body: body,
          ticket: data.contact.number,
          whatsappId: data.whatsappId,
          participants: selectedOption.map(option => option.toString())
        }
        const response = await api.post("/messages/mentionParticipantMessage/" + ghostId, payload);
        if (onSave) {
          onSave(response.data);
        }
        setBody('');
        handleClose();
        toast.success(i18n.t("mentionModal.success"));
      } catch (err) {
        toastError(err);
      } finally {
        setIsLoading(false);
      }
    }
    if (showOptionsInput){
      setIsLoading(true);
      const { data } = await api.get("/tickets/" + ghostId);
      try {
        const payload = {
          body: body,
          ticket: data.contact.number,
          whatsappId: data.whatsappId
        }
        const response = await api.post("/messages/mentionMessage/" + ghostId, payload);
        if (onSave) {
          onSave(response.data);
        }
        setBody('');
        handleClose();
        toast.success(i18n.t("mentionModal.success"));
      } catch (err) {
        toastError(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {ghostListId
            ? `${i18n.t("mentionModal.title.edit")}`
            : `${i18n.t("mentionModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={ghostList}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              // handleSaveGhostList(values);
              handleSaveMentionParticipant(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textGhostListContainer}>
                  <Field
                    as={TextField}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    label={i18n.t("mentionModal.form.message")}
                    name="body"
                    error={touched.body && Boolean(errors.body)}
                    helperText={touched.body && errors.body}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    minRows={5}
                    fullWidth
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showOptionsInput}
                        onChange={handleSwitchChange}
                        color="primary"
                      />
                    }
                    label={i18n.t("mentionModal.form.showOptionsInput")}
                  />
                  {!showOptionsInput && (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="select-multiple-checkbox">{i18n.t("mentionModal.form.choice")}</InputLabel>
                      <Select
                        multiple
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        renderValue={(selected) => `${selected.length} ${i18n.t("mentionModal.form.selected")}`}
                        label={i18n.t("mentionModal.form.choice")}
                        input={<OutlinedInput id="select-multiple-checkbox" label={i18n.t("mentionModal.form.choice")} />}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              width: 250,
                            },
                          },
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Checkbox checked={selectedOption.indexOf(option) > -1} />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("mentionModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {ghostListId
                    ? `${i18n.t("mentionModal.buttons.okEdit")}`
                    : `${i18n.t("mentionModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
                {isLoading && <p>Aguarde, carregando todos os contatos...</p>}
                {loading && <p>Aguarde, carregando todos os contatos...</p>}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default GhostModal;
