import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import GreetingMessageModal from "../../components/GreetingMessageModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_GREETING_MESSAGE") {
    const greetingMessage = action.payload;
    const newGreetingMessage = [];

    greetingMessage.forEach((message) => {
      const greetingMessageIndex = state.findIndex((m) => m.id === message.id);
      if (greetingMessageIndex !== -1) {
        state[greetingMessageIndex] = message;
      } else {
        newGreetingMessage.push(message);
      }
    });

    return [...state, ...newGreetingMessage];
  }

  if (action.type === "UPDATE_GREETING_MESSAGE") {
    const greetingMessage = action.payload;
    const greetingMessageIndex = state.findIndex((m) => m.id === greetingMessage.id);

    if (greetingMessageIndex !== -1) {
      state[greetingMessageIndex] = greetingMessage;
      return [...state];
    } else {
      return [greetingMessage, ...state];
    }
  }

  if (action.type === "DELETE_GREETING_MESSAGE") {
    const greetingMessageId = action.payload;

    const greetingMessageIndex = state.findIndex((m) => m.id === greetingMessageId);
    if (greetingMessageIndex !== -1) {
      state.splice(greetingMessageIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const GreetingMessage = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [greetingMessage, dispatch] = useReducer(reducer, []);
  const [selectedGreetingMessage, setSelectedGreetingMessage] = useState(null);
  const [greetingMessageModalOpen, setGreetingMessageModalOpen] = useState(false);
  const [deletingGreetingMessage, setDeletingGreetingMessage] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchGreetingMessage = async () => {
        try {
          const { data } = await api.get("/greetingMessage/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_GREETING_MESSAGE", payload: data.greetingMessage });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchGreetingMessage();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("greetingMessage", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_GREETING_MESSAGE", payload: data.greetingMessage });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_GREETING_MESSAGE",
          payload: +data.greetingMessageId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenGreetingMessageModal = () => {
    setSelectedGreetingMessage(null);
    setGreetingMessageModalOpen(true);
  };

  const handleCloseGreetingMessageModal = () => {
    setSelectedGreetingMessage(null);
    setGreetingMessageModalOpen(false);
  };

  const handleEditGreetingMessage = (greetingMessage) => {
    setSelectedGreetingMessage(greetingMessage);
    setGreetingMessageModalOpen(true);
  };

  const handleDeleteGreetingMessage = async (greetingMessageId) => {
    try {
      await api.delete(`/greetingMessage/${greetingMessageId}`);
      toast.success(i18n.t("greetings.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingGreetingMessage(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const confirmDeleteAllGreetingMessage = () => {
    const confirm = window.confirm(i18n.t("greetings.popup.confirm"));
    if (confirm) {
      handleDeleteAllGreetingMessage();
    }
  };

  const handleDeleteAllGreetingMessage = async () => {
    try {
      await api.delete(`/greetingMessageAll`);
      toast.success(i18n.t("greetings.toasts.deleted"));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      toastError(err);
    }
    setDeletingGreetingMessage(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingGreetingMessage &&
          `${i18n.t("greetings.confirmationModal.deleteTitle")} ${
            deletingGreetingMessage.groupId
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteGreetingMessage(deletingGreetingMessage.id)}
      >
        {i18n.t("greetings.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <GreetingMessageModal
        open={greetingMessageModalOpen}
        onClose={handleCloseGreetingMessageModal}
        aria-labelledby="form-dialog-title"
        greetingMessageId={selectedGreetingMessage && selectedGreetingMessage.id}
      ></GreetingMessageModal>
      <MainHeader>
        <Title>{i18n.t("greetings.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("greetings.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenGreetingMessageModal}
          >
            {i18n.t("greetings.buttons.add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmDeleteAllGreetingMessage}
          >
            {i18n.t("greetings.buttons.delete")}
          </Button> 
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("greetings.table.shortcut")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("greetings.table.message")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("greetings.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {greetingMessage.map((message) => (
                <TableRow key={message.id}>
                  <TableCell align="center">{message.groupId === 'all' ? 'Todos os Grupos' : message.groupId}</TableCell>
                  <TableCell align="center">{message.message}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditGreetingMessage(message)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingGreetingMessage(message);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default GreetingMessage;
