import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import GhostListModal from "../../components/GhostListModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_GHOST_LIST") {
    const ghostList = action.payload;
    const newGhostList = [];

    ghostList.forEach((ghost) => {
      const ghostListIndex = state.findIndex((g) => g.id === ghost.id);
      if (ghostListIndex !== -1) {
        state[ghostListIndex] = ghost;
      } else {
        newGhostList.push(ghost);
      }
    });

    return [...state, ...newGhostList];
  }

  if (action.type === "UPDATE_GHOST_LIST") {
    const ghostList = action.payload;
    const ghostListIndex = state.findIndex((g) => g.id === ghostList.id);

    if (ghostListIndex !== -1) {
      state[ghostListIndex] = ghostList;
      return [...state];
    } else {
      return [ghostList, ...state];
    }
  }

  if (action.type === "DELETE_GHOST_LIST") {
    const ghostListId = action.payload;

    const ghostListIndex = state.findIndex((g) => g.id === ghostListId);
    if (ghostListIndex !== -1) {
      state.splice(ghostListIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const GhostList = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [ghostList, dispatch] = useReducer(reducer, []);
  const [selectedGhostList, setSelectedGhostList] = useState(null);
  const [ghostListModalOpen, setGhostListModalOpen] = useState(false);
  const [deletingGhostList, setDeletingGhostList] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchGhostList = async () => {
        try {
          const { data } = await api.get("/ghostList/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_GHOST_LIST", payload: data.ghostList });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchGhostList();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("ghostList", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_GHOST_LIST", payload: data.ghostList });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_GHOST_LIST",
          payload: +data.ghostListId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenGhostListModal = () => {
    setSelectedGhostList(null);
    setGhostListModalOpen(true);
  };

  const handleCloseGhostListModal = () => {
    setSelectedGhostList(null);
    setGhostListModalOpen(false);
  };

  const handleEditGhostList = (ghostList) => {
    setSelectedGhostList(ghostList);
    setGhostListModalOpen(true);
  };

  const handleDeleteGhostList = async (ghostListId) => {
    try {
      await api.delete(`/ghostList/${ghostListId}`);
      toast.success(i18n.t("ghostList.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingGhostList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const confirmDeleteAllGhostMessage = () => {
    const confirm = window.confirm(i18n.t("ghostList.popup.confirm"));
    if (confirm) {
      handleDeleteAllGhostList();
    }
  };

  const handleDeleteAllGhostList = async () => {
    try {
      await api.delete(`/ghostListAll`);
      toast.success(i18n.t("ghostList.toasts.deleted"));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      toastError(err);
    }
    setDeletingGhostList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingGhostList &&
          `${i18n.t("ghostList.confirmationModal.deleteTitle")} ${
            deletingGhostList.shortcut
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteGhostList(deletingGhostList.id)}
      >
        {i18n.t("ghostList.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <GhostListModal
        open={ghostListModalOpen}
        onClose={handleCloseGhostListModal}
        aria-labelledby="form-dialog-title"
        ghostListId={selectedGhostList && selectedGhostList.id}
      ></GhostListModal>
      <MainHeader>
        <Title>{i18n.t("ghostList.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("ghostList.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenGhostListModal}
          >
            {i18n.t("ghostList.buttons.add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmDeleteAllGhostMessage}
          >
            {i18n.t("ghostList.buttons.delete")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("ghostList.table.shortcut")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("ghostList.table.message")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("ghostList.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {ghostList.map((ghost) => (
                <TableRow key={ghost.id}>
                  <TableCell align="center">{ghost.shortcut}</TableCell>
                  <TableCell align="center">{ghost.message}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditGhostList(ghost)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingGhostList(ghost);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default GhostList;
