import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },

  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const SingleGroupList = () => {
  const classes = useStyles();
  const [whatsappId, setWhatsappId] = useState("");
  const [formattedGroups, setFormattedGroups] = useState([]);

  const handleChangeSetting = async () => {
    const data = {
      whatsappId: parseInt(whatsappId),
    };

    try {
      const response = await api.post(`/zdgSingleListGroup/`, data);
      setFormattedGroups(response.data.groups);
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleCopyGroups = () => {
    const groupsText = formattedGroups.join('\n');
    navigator.clipboard.writeText(groupsText);
    toast.success("Grupos copiados para a área de transferência.");
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="sm">
        <Typography variant="body2" gutterBottom>
          {i18n.t("mainDrawer.listItems.listGroup")}
        </Typography>
        <Paper className={classes.paper}>
          <TextField
            label={i18n.t("commonLabels.whatsAppID")}
            margin="dense"
            variant="outlined"
            fullWidth
            value={whatsappId}
            onChange={(e) => setWhatsappId(e.target.value)}
          />
        </Paper>
        <Paper className={classes.paper}>
          {formattedGroups.length > 0 && (
            <Container className={classes.container} maxWidth="sm">
              <Typography variant="body2" gutterBottom>
                Grupos:
              </Typography>
              {formattedGroups.map((group, index) => (
                <Paper key={index} className={classes.paper}>
                  <Typography variant="body1">{group}</Typography>
                </Paper>
              ))}
            </Container>
          )}
        </Paper>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeSetting}
          style={{ marginRight: '8px' }}
        >
          {i18n.t("commonLabels.action")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCopyGroups}
        >
          {i18n.t("commonLabels.copyData")}
        </Button>
      </Container>
    </div>
  );
};

export default SingleGroupList;
