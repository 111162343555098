import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },

  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const SingleGroupCreate = () => {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [whatsappId, setWhatsappId] = useState("");
  const [number, setNumber] = useState("");

  const handleChangeSetting = async () => {
    const data = {
      title: title,
      whatsappId: parseInt(whatsappId),
      number: number,
    };

    try {
      await api.post(`/zdgSingleCreateGroup/`, data);
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="sm">
        <Typography variant="body2" gutterBottom>
          {i18n.t("mainDrawer.listItems.createGroup")}
        </Typography>
        <Paper className={classes.paper}>
          <TextField
            label={i18n.t("commonLabels.title")}
            margin="dense"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Paper>
        <Paper className={classes.paper}>
          <TextField
            label={i18n.t("commonLabels.whatsAppID")}
            margin="dense"
            variant="outlined"
            fullWidth
            value={whatsappId}
            onChange={(e) => setWhatsappId(e.target.value)}
          />
        </Paper>
        <Paper className={classes.paper}>
          <TextField
            label={i18n.t("commonLabels.number")}
            margin="dense"
            variant="outlined"
            fullWidth
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </Paper>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeSetting}
        >
          {i18n.t("commonLabels.action")}
        </Button>
      </Container>
    </div>
  );
};

export default SingleGroupCreate;
