import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textWordListContainer: {
    width: "100%",
  },
}));

const WordListSchema = Yup.object().shape({
  word: Yup.string()
    .required("Required"),
});

const WordListsModal = ({
  open,
  onClose,
  wordListId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    groupId: "",
    word: "",
  };

  const [wordList, setWordList] = useState(initialState);
  const [showGroupId, setShowGroupId] = useState(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchWordList = async () => {
      if (initialValues) {
        setWordList((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!wordListId) return;

      try {
        const { data } = await api.get(`/wordList/${wordListId}`);
        if (isMounted.current) {
          setWordList(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchWordList();
  }, [wordListId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setWordList(initialState);
  };

  const handleSaveWordList = async (values) => {
    try {
      if (showGroupId){
        if (wordListId) {
          await api.put(`/wordList/${wordListId}`, values);
          handleClose();
        } else {
          const { data } = await api.post("/wordList", values);
          if (onSave) {
            onSave(data);
          }
          handleClose();
        }
      }
      if (!showGroupId){
        const updatedValues = {
          groupId: 'all',
          word: values.word
        }
        if (wordListId) {
          await api.put(`/wordList/${wordListId}`, updatedValues);
          handleClose();
        } else {
          const { data } = await api.post("/wordList", updatedValues);
          if (onSave) {
            onSave(data);
          }
          handleClose();
        }
      }
      toast.success(i18n.t("wordListModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {wordListId
            ? `${i18n.t("wordListModal.title.edit")}`
            : `${i18n.t("wordListModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={wordList}
          enableReinitialize={true}
          validationSchema={WordListSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWordList(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textWordListContainer}>
                  {showGroupId && (
                    <Field
                      as={TextField}
                      label={i18n.t("wordListModal.form.groupId")}
                      name="groupId"
                      autoFocus
                      error={touched.groupId && Boolean(errors.groupId)}
                      helperText={touched.groupId && errors.groupId}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      fullWidth
                    />
                  )}
                </div>
                <div className={classes.textWordListContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("wordListModal.form.word")}
                    name="word"
                    error={touched.word && Boolean(errors.word)}
                    helperText={touched.word && errors.word}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    minRows={5}
                    fullWidth
                  />
                </div>
                <div>
                  <Switch
                    checked={!showGroupId}
                    onChange={() => setShowGroupId(!showGroupId)}
                  />
                  <span>{i18n.t("wordListModal.buttons.toggle")}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("wordListModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {wordListId
                    ? `${i18n.t("wordListModal.buttons.okEdit")}`
                    : `${i18n.t("wordListModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default WordListsModal;
