import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import WordListModal from "../../components/WordListModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_WORD_LIST") {
    const wordList = action.payload;
    const newWordList = [];

    wordList.forEach((word) => {
      const wordListIndex = state.findIndex((w) => w.id === word.id);
      if (wordListIndex !== -1) {
        state[wordListIndex] = word;
      } else {
        newWordList.push(word);
      }
    });

    return [...state, ...newWordList];
  }

  if (action.type === "UPDATE_WORD_LIST") {
    const wordList = action.payload;
    const wordListIndex = state.findIndex((w) => w.id === wordList.id);

    if (wordListIndex !== -1) {
      state[wordListIndex] = wordList;
      return [...state];
    } else {
      //aqui
      return [wordList, ...state];
    }
  }

  if (action.type === "DELETE_WORD_LIST") {
    const wordListId = action.payload;

    const wordListIndex = state.findIndex((w) => w.id === wordListId);
    if (wordListIndex !== -1) {
      state.splice(wordListIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const WordList = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [wordList, dispatch] = useReducer(reducer, []);
  const [selectedWordList, setSelectedWordList] = useState(null);
  const [wordListModalOpen, setWordListModalOpen] = useState(false);
  const [deletingWordList, setDeletingWordList] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchWordList = async () => {
        try {
          const { data } = await api.get("/wordList/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_WORD_LIST", payload: data.wordList });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchWordList();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("wordList", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_WORD_LIST", payload: data.wordList });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_WORD_LIST",
          payload: +data.wordListId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenWordListModal = () => {
    setSelectedWordList(null);
    setWordListModalOpen(true);
  };

  const handleCloseWordListModal = () => {
    setSelectedWordList(null);
    setWordListModalOpen(false);
  };

  const handleEditWordList = (wordList) => {
    setSelectedWordList(wordList);
    setWordListModalOpen(true);
  };

  const handleDeleteWordList = async (wordListId) => {
    try {
      await api.delete(`/wordList/${wordListId}`);
      toast.success(i18n.t("wordList.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingWordList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const confirmDeleteAllWordMessage = () => {
    const confirm = window.confirm(i18n.t("wordList.popup.confirm"));
    if (confirm) {
      handleDeleteAllWordList();
    }
  };

  const handleDeleteAllWordList = async () => {
    try {
      await api.delete(`/wordListAll`);
      toast.success(i18n.t("wordList.toasts.deleted"));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      toastError(err);
    }
    setDeletingWordList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingWordList &&
          `${i18n.t("wordList.confirmationModal.deleteTitle")} ${
            deletingWordList.shortcut
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteWordList(deletingWordList.id)}
      >
        {i18n.t("wordList.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <WordListModal
        open={wordListModalOpen}
        onClose={handleCloseWordListModal}
        aria-labelledby="form-dialog-title"
        wordListId={selectedWordList && selectedWordList.id}
      ></WordListModal>
      <MainHeader>
        <Title>{i18n.t("wordList.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("wordList.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenWordListModal}
          >
            {i18n.t("wordList.buttons.add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmDeleteAllWordMessage}
          >
            {i18n.t("wordList.buttons.delete")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("wordList.table.shortcut")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("wordList.table.message")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("wordList.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {wordList.map((word) => (
                <TableRow key={word.id}>
                  <TableCell align="center">{word.groupId === 'all' ? 'Todos os Grupos' : word.groupId}</TableCell>
                  <TableCell align="center">{word.word}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditWordList(word)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingWordList(word);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default WordList;
