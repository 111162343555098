const messages = {
  en: {
    translations: {
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets today: ",
          },
        },
        messages: {
          inAttendance: {
            title: "In Service"
          },
          waiting: {
            title: "Waiting"
          },
          closed: {
            title: "Closed"
          }
        }
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Name",
          default: "Default",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Read QrCode to start the session",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
      },
      ghostListModal: {
        title: {
          add: "Add Ghost Marking",
          edit: "Edit Ghost Marking",
        },
        form: {
          shortcut: "Shortcut",
          message: "Ghost Marking",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Ghost Marking saved successfully.",
      },
      wordListModal: {
        title: {
          add: "Add Forbidden Word",
          edit: "Edit Forbidden Word",
        },
        form: {
          groupId: "Group ID",
          word: "Word",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          toggle: "All Groups"
        },
        success: "Forbidden Word saved successfully.",
      },
      banListModal: {
        title: {
          add: "Add Banned Number",
          edit: "Edit Banned Number",
        },
        form: {
          groupId: "Group ID",
          ban: "Number",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          toggle: "All Groups"
        },
        success: "Banned Number saved successfully.",
      },
      greetingMessageModal: {
        title: {
          add: "Add Greetings Message",
          edit: "Edit Greetings Message",
        },
        form: {
          groupId: "Group ID",
          word: "Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          toggle: "All Groups"
        },
        success: "Greetings Message saved successfully.",
      },
      farewellMessageModal: {
        title: {
          add: "Add Farewell Message",
          edit: "Edit Farewell Message",
        },
        form: {
          groupId: "Group ID",
          word: "Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          toggle: "All Groups"
        },
        success: "Farewell Message saved successfully.",
      },
      ghostModal: {
        title: {
          add: "Send Ghost Marking",
          edit: "Edit Ghost Marking",
        },
        form: {
          shortcut: "Shortcut",
          message: "Ghost Marking",
        },
        buttons: {
          okAdd: "Send",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Ghost Marking sent successfully.",
      },
      mentionModal: {
        title: {
          add: "Send Mention",
          edit: "Edit Mention",
        },
        form: {
          shortcut: "Shortcut",
          message: "Mention",
          choice: "Choose the numbers",
          selected: "selected",
          showOptionsInput: "Mention all participants"
        },
        buttons: {
          okAdd: "Send",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Mention sent successfully.",
      },
      participantListModal: {
        title: {
          add: "Add Lead",
          edit: "Edit Lead",
        },
        form: {
          groupId: "Group ID",
          ban: "Number",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          toggle: "All Groups"
        },
        success: "Lead saved successfully.",
      },      
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
          whatsapp: "Default Connection",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        fieldQueueLabel: "Transfer to queue",
        fieldConnectionLabel: "Transfer to connection",
        fieldQueuePlaceholder: "Please select a queue",
        fieldConnectionPlaceholder: "Please select a connection",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term.",
        connectionTitle: "Connection that is currently being used.",
        buttons: {
          accept: "Accept",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          tickets: "Tickets",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          wordList: "Forbidden Words",
          queues: "Queues",
          administration: "Administration",
          users: "Users",
          settings: "Settings",
          createGroup: "Create Group",
          listGroup: "List Groups",
          listParticipant: "List Participants",
          ghostMention: "Ghost Mention",
          forbiddenWord: "Forbidden Words",
          bannedNumbers: "Banned Numbers",
          greetingsMessage: "Greetings",
          farewellMessage: "Farewell",
          leads: "Leads",
          singleGroupChangeTitle: "Change Title",
          singleGroupChangeDescription: "Change Description",
          singleGroupChangePicture: "Change Picture",
          singleGroupSetAdminOnly: "Set Admin Only",
          singleGroupPromote: "Promote Admin",
          singleGroupDemote: "Demote Admin",
          singleGroupAdd: "Add Participant",
          singleGroupRemove: "Remove Participant",
          singleGroupSendMessage: "Send Message",
          singleGroupSendMedia: "Send Media",
          singleGroupSendVoice: "Send Voice",
          bulkGroupChangeTitle: "Change Title",
          bulkGroupChangeDescription: "Change Description",
          bulkGroupChangePicture: "Change Picture",
          bulkGroupSetAdminOnly: "Set Admin Only",
          bulkGroupPromote: "Promote Admin",
          bulkGroupDemote: "Demote Admin",
          bulkGroupAdd: "Add Participant",
          bulkGroupRemove: "Remove Participant",
          bulkGroupSendMessage: "Send Message",
          bulkGroupSendMedia: "Send Media",
          bulkGroupSendVoice: "Send Voice",
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      ghostList: {
        title: "Ghost Marking",
        table: {
          shortcut: "Shortcut",
          message: "Message",
          actions: "Actions",
        },
        buttons: {
          add: "Add Ghost Marking",
          delete: "Delete Ghost Marking",
        },
        toasts: {
          deleted: "Ghost Marking successfully deleted.",
        },
        popup: {
          confirm: "Are you sure you want to delete all ghost markings?",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Ghost Marking: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      wordList: {
        title: "Forbidden Words",
        table: {
          shortcut: "Group ID",
          message: "Forbidden Word",
          actions: "Actions",
        },
        buttons: {
          add: "Add Forbidden Word",
          delete: "Delete Forbidden Word",
        },
        toasts: {
          deleted: "Forbidden Word successfully deleted.",
        },
        popup: {
          confirm: "Are you sure you want to delete all forbidden words?",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Forbidden Word: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      banList: {
        title: "Banned Numbers",
        table: {
          shortcut: "Group ID",
          message: "Banned Number",
          actions: "Actions",
        },
        buttons: {
          add: "Add Banned Number",
          delete: "Delete Banned Number",
        },
        toasts: {
          deleted: "Banned Number successfully deleted.",
        },
        popup: {
          confirm: "Are you sure you want to delete all banned numbers?",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Banned Number: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      greetings: {
        title: "Greetings Message",
        table: {
          shortcut: "Group ID",
          message: "Message",
          actions: "Actions",
        },
        buttons: {
          add: "Add Greetings Message",
          delete: "Delete Greetings Message",
        },
        toasts: {
          deleted: "Greetings Message successfully deleted.",
        },
        popup: {
          confirm: "Are you sure you want to delete all greetings messages?",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Greetings Message: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      farewell: {
        title: "Farewell Message",
        table: {
          shortcut: "Group ID",
          message: "Message",
          actions: "Actions",
        },
        buttons: {
          add: "Add Farewell Message",
          delete: "Delete Farewell Message",
        },
        toasts: {
          deleted: "Farewell Message successfully deleted.",
        },
        popup: {
          confirm: "Are you sure you want to delete all farewell messages?",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Farewell Message: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      participants: {
        title: "Leads",
        table: {
          shortcut: "Group ID",
          name: "User",
          user: "ID",
          actions: "Actions",
        },
        buttons: {
          add: "Add Leads",
          delete: "Delete Leads",
        },
        toasts: {
          deleted: "Leads successfully deleted.",
        },
        popup: {
          confirm: "Are you sure you want to delete all leads?",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Leads: ",
          deleteMessage: "This action cannot be undone.",
        },
      },      
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          whatsapp: "Default Connection",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Type a message or press ''/'' to use the registered quick responses",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      commonLabels: {
        whatsAppID: "WhatsApp ID",
        groupId: "Group ID",
        number: "Number",
        title: "Title",
        description: "Description",
        picture: "Image URL",
        onOff: "On/Off",
        participantsList: "Participants (comma-separated)",
        message: "Message",
        media: "Media/File URL",
        caption: "Media/File Title",
        action: "Execute",
        copyData: "Copy Data",
        copyComma: "Copy (Comma)",
        copyLine: "Copy (Line)",
        excelExport: "Export to Excel"
      },      
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating ticket on database.",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED:
          "Greeting message is required if there is more than one queue.",
      },
    },
  },
};

export { messages };
