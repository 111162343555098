import React, { useState, useEffect, useRef } from "react";

import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textGhostListContainer: {
    width: "100%",
  },
}));

const GhostModal = ({
  open,
  onClose,
  ghostListId,
  onSave,
  ghostId
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const [body, setBody] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    body: "",
    ticket: "",
    whatsappId: "",
  };

  const [ghostList, setGhostList] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    onClose();
    setGhostList(initialState);
  };

  const handleSaveGhostList = async () => {
    setIsLoading(true);
    const { data } = await api.get("/tickets/" + ghostId);
    console.log(data)
    try {
      const payload = {
        body: body,
        ticket: data.contact.number,
        whatsappId: data.whatsappId
      }
      console.log(payload)
      const response = await api.post("/messages/ghostMessage/" + ghostId, payload);
      if (onSave) {
        onSave(response.data);
      }
      setBody('');
      handleClose();
      toast.success(i18n.t("ghostModal.success"));
    } catch (err) {
      toastError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {ghostListId
            ? `${i18n.t("ghostModal.title.edit")}`
            : `${i18n.t("ghostModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={ghostList}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveGhostList(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textGhostListContainer}>
                  <Field
                    as={TextField}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    label={i18n.t("ghostModal.form.message")}
                    name="body"
                    error={touched.body && Boolean(errors.body)}
                    helperText={touched.body && errors.body}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    minRows={5}
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("ghostModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {ghostListId
                    ? `${i18n.t("ghostModal.buttons.okEdit")}`
                    : `${i18n.t("ghostModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
                {isLoading && <p>Aguarde, carregando todos os contatos...</p>}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default GhostModal;
