import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
// import { Edit, DeleteOutline } from "@material-ui/icons";
import { DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import GroupLinkListModal from "../../components/GroupLinkListModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_GROUP_LINK_LIST") {
    const groupLinkList = action.payload;
    const newGroupLinkList = [];

    groupLinkList.forEach((groupLink) => {
      const groupLinkListIndex = state.findIndex((w) => w.id === groupLink.id);
      if (groupLinkListIndex !== -1) {
        state[groupLinkListIndex] = groupLink;
      } else {
        newGroupLinkList.push(groupLink);
      }
    });

    return [...state, ...newGroupLinkList];
  }

  if (action.type === "UPDATE_GROUP_LINK_LIST") {
    const groupLinkList = action.payload;
    const groupLinkListIndex = state.findIndex((w) => w.id === groupLinkList.id);

    if (groupLinkListIndex !== -1) {
      state[groupLinkListIndex] = groupLinkList;
      return [...state];
    } else {
      //aqui
      return [groupLinkList, ...state];
    }
  }

  if (action.type === "DELETE_GROUP_LINK_LIST") {
    const groupLinkListId = action.payload;

    const groupLinkListIndex = state.findIndex((w) => w.id === groupLinkListId);
    if (groupLinkListIndex !== -1) {
      state.splice(groupLinkListIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const GroupLinkList = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [groupLinkList, dispatch] = useReducer(reducer, []);
  const [selectedGroupLinkList, setSelectedGroupLinkList] = useState(null);
  const [groupLinkListModalOpen, setGroupLinkListModalOpen] = useState(false);
  const [deletingGroupLinkList, setDeletingGroupLinkList] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchGroupLinkList = async () => {
        try {
          const { data } = await api.get("/groupLinkList/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_GROUP_LINK_LIST", payload: data.groupLinkList });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchGroupLinkList();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("groupLinkList", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_GROUP_LINK_LIST", payload: data.groupLinkList });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_GROUP_LINK_LIST",
          payload: +data.groupLinkListId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenGroupLinkListModal = () => {
    setSelectedGroupLinkList(null);
    setGroupLinkListModalOpen(true);
  };

  const handleCloseGroupLinkListModal = () => {
    setSelectedGroupLinkList(null);
    setGroupLinkListModalOpen(false);
  };

  // const handleEditGroupLinkList = (groupLinkList) => {
  //   setSelectedGroupLinkList(groupLinkList);
  //   setGroupLinkListModalOpen(true);
  // };

  const handleDeleteGroupLinkList = async (groupLinkListId) => {
    try {
      await api.delete(`/groupLinkList/${groupLinkListId}`);
      toast.success(i18n.t("groupLinkList.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingGroupLinkList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const confirmDeleteAllGroupLinkMessage = () => {
    const confirm = window.confirm(i18n.t("groupLinkList.popup.confirm"));
    if (confirm) {
      handleDeleteAllGroupLinkList();
    }
  };

  const handleDeleteAllGroupLinkList = async () => {
    try {
      await api.delete(`/groupLinkListAll`);
      toast.success(i18n.t("groupLinkList.toasts.deleted"));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      toastError(err);
    }
    setDeletingGroupLinkList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const copyLink = () => {
    const linkToCopy = window.location.href.split('/GroupLinkList')[0] + '/link';
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = linkToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    alert('Link copiado para a área de transferência! Abra em um navegador que não esteja logado no sistema!');
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingGroupLinkList &&
          `${i18n.t("groupLinkList.confirmationModal.deleteTitle")} ${
            deletingGroupLinkList.shortcut
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteGroupLinkList(deletingGroupLinkList.id)}
      >
        {i18n.t("groupLinkList.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <GroupLinkListModal
        open={groupLinkListModalOpen}
        onClose={handleCloseGroupLinkListModal}
        aria-labelledby="form-dialog-title"
        groupLinkListId={selectedGroupLinkList && selectedGroupLinkList.id}
      ></GroupLinkListModal>
      <MainHeader>
        <Title>{i18n.t("groupLinkList.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("groupLinkList.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={copyLink}
          >
            {/* {i18n.t("groupLinkList.buttons.add")} */}
            {'Link'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenGroupLinkListModal}
          >
            {i18n.t("groupLinkList.buttons.add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmDeleteAllGroupLinkMessage}
          >
            {i18n.t("groupLinkList.buttons.delete")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
            <TableCell align="center">
                {/* {i18n.t("groupLinkList.table.groupId")} */}
                {'ID'}
              </TableCell>
              <TableCell align="center">
                {i18n.t("groupLinkList.table.groupId")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("groupLinkList.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("groupLinkList.table.link")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("groupLinkList.table.participants")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("groupLinkList.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {groupLinkList.map((groupLink) => (
                <TableRow key={groupLink.id}>
                  <TableCell align="center">{groupLink.id}</TableCell>
                  <TableCell align="center">{groupLink.groupId}</TableCell>
                  <TableCell align="center">{groupLink.name}</TableCell>
                  <TableCell align="center">{groupLink.link}</TableCell>
                  <TableCell align="center">{groupLink.participants}</TableCell>
                  <TableCell align="center">
                    {/* <IconButton
                      size="small"
                      onClick={() => handleEditGroupLinkList(groupLink)}
                    >
                      <Edit />
                    </IconButton> */}

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingGroupLinkList(groupLink);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default GroupLinkList;
