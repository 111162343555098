import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textGreetingMessageContainer: {
    width: "100%",
  },
}));

const GreetingMessageSchema = Yup.object().shape({
  message: Yup.string()
    .required("Required"),
});

const GreetingMessageModal = ({
  open,
  onClose,
  greetingMessageId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    groupId: "",
    message: "",
  };

  const [greetingMessage, setGreetingMessage] = useState(initialState);
  const [showGroupId, setShowGroupId] = useState(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchGreetingMessage = async () => {
      if (initialValues) {
        setGreetingMessage((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!greetingMessageId) return;

      try {
        const { data } = await api.get(`/greetingMessage/${greetingMessageId}`);
        if (isMounted.current) {
          setGreetingMessage(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchGreetingMessage();
  }, [greetingMessageId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setGreetingMessage(initialState);
  };

  const handleSaveGreetingMessage = async (values) => {
    try {
      if (showGroupId){
        if (greetingMessageId) {
          await api.put(`/greetingMessage/${greetingMessageId}`, values);
          handleClose();
        } else {
          const { data } = await api.post("/greetingMessage", values);
          if (onSave) {
            onSave(data);
          }
          handleClose();
        }
      }
      if (!showGroupId){
        const updatedValues = {
          groupId: 'all',
          message: values.message
        }
        if (greetingMessageId) {
          await api.put(`/greetingMessage/${greetingMessageId}`, updatedValues);
          handleClose();
        } else {
          const { data } = await api.post("/greetingMessage", updatedValues);
          if (onSave) {
            onSave(data);
          }
          handleClose();
        }
      }
      toast.success(i18n.t("greetingMessageModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {greetingMessageId
            ? `${i18n.t("greetingMessageModal.title.edit")}`
            : `${i18n.t("greetingMessageModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={greetingMessage}
          enableReinitialize={true}
          validationSchema={GreetingMessageSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveGreetingMessage(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textGreetingMessageContainer}>
                  {showGroupId && (
                    <Field
                      as={TextField}
                      label={i18n.t("greetingMessageModal.form.groupId")}
                      name="groupId"
                      autoFocus
                      error={touched.groupId && Boolean(errors.groupId)}
                      helperText={touched.groupId && errors.groupId}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      fullWidth
                    />
                  )}
                </div>
                <div className={classes.textGreetingMessageContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("greetingMessageModal.form.word")}
                    name="message"
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    minRows={5}
                    fullWidth
                  />
                </div>
                <div>
                  <Switch
                    checked={!showGroupId}
                    onChange={() => setShowGroupId(!showGroupId)}
                  />
                  <span>{i18n.t("greetingMessageModal.buttons.toggle")}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("greetingMessageModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {greetingMessageId
                    ? `${i18n.t("greetingMessageModal.buttons.okEdit")}`
                    : `${i18n.t("greetingMessageModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default GreetingMessageModal;
