const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoje: ",
          },
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          }
        }
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          farewellMessage: "Mensagem de despedida"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Sincronizar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      ghostListModal: {
        title: {
          add: "Adicionar Marcação Fantasma",
          edit: "Editar Marcação Fantasma",
        },
        form: {
          shortcut: "Atalho",
          message: "Marcação Fantasma",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Marcação Fantasma salva com sucesso.",
      },
      wordListModal: {
        title: {
          add: "Adicionar Palavra Proibida",
          edit: "Editar Palavra Proibida",
        },
        form: {
          groupId: "ID Grupo",
          word: "Palavra",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          toggle: "Todos os Grupos"
        },
        success: "Palavra Proibida salva com sucesso.",
      },
      groupLinkListModal: {
        title: {
          add: "Adicionar Grupo",
          edit: "Editar Grupo",
        },
        form: {
          groupId: "ID Grupo",
          name: "Nome",
          link: "Link",
          participants: "Participantes"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          toggle: "Todos os Grupos"
        },
        success: "Grupo salvo com sucesso.",
      },
      banListModal: {
        title: {
          add: "Adicionar Número Banido",
          edit: "Editar Número Banido",
        },
        form: {
          groupId: "ID Grupo",
          ban: "Número",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          toggle: "Todos os Grupos"
        },
        success: "Número Banido salvo com sucesso.",
      },
      greetingMessageModal: {
        title: {
          add: "Adicionar Mensagem de Saudação",
          edit: "Editar Mensagem de Saudação",
        },
        form: {
          groupId: "ID Grupo",
          word: "Mensagem",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          toggle: "Todos os Grupos"
        },
        success: "Mensagem de Saudação salva com sucesso.",
      },
      farewellMessageModal: {
        title: {
          add: "Adicionar Mensagem de Despedida",
          edit: "Editar Mensagem de Despedida",
        },
        form: {
          groupId: "ID Grupo",
          word: "Mensagem",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          toggle: "Todos os Grupos"
        },
        success: "Mensagem de Despedida salva com sucesso.",
      },
      ghostModal: {
        title: {
          add: "Enviar Marcação Fantasma",
          edit: "Editar Marcação Fantasma",
        },
        form: {
          shortcut: "Atalho",
          message: "Marcação Fantasma",
        },
        buttons: {
          okAdd: "Enviar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Marcação Fantasma enviada com sucesso.",
      },
      mentionModal: {
        title: {
          add: "Enviar Menção",
          edit: "Editar Menção",
        },
        form: {
          shortcut: "Atalho",
          message: "Menção",
          choice: "Escolha os números",
          selected: "selecionado(s)",
          showOptionsInput: "Mencionar todos os participantes"
        },
        buttons: {
          okAdd: "Enviar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Menção enviada com sucesso.",
      },
      participantListModal: {
        title: {
          add: "Adicionar Lead",
          edit: "Editar Lead",
        },
        form: {
          groupId: "ID Grupo",
          ban: "Número",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          toggle: "Todos os Grupos"
        },
        success: "Lead salvo com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione uma fila",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Grupos",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        buttons: {
          accept: "Aceitar",
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Atender",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          wordList: "Palavra Proibida",
          queues: "Filas",
          administration: "Administração",
          users: "Usuários",
          settings: "Configurações",
          createGroup: "Criar Grupo",
          listGroup: "Listar Grupos",
          listParticipant: "Listar Participantes",
          ghostMention: "Menção Fantasma",
          forbiddenWord: "Palavras Proibidas",
          bannedNumbers: "Números Banidos",
          greetingsMessage: "Saudação",
          farewellMessage: "Despedida",
          leads: "Leads",
          singleGroupChangeTitle: "Mudar Título",
          singleGroupChangeDescription: "Mudar Descrição",
          singleGroupChangePicture: "Mudar Imagem",
          singleGroupSetAdminOnly: "Permissão",
          singleGroupPromote: "Promover Admin",
          singleGroupDemote: "Demover Admin",
          singleGroupAdd: "Adicionar Participante",
          singleGroupRemove: "Remover Participante",
          singleGroupSendMessage: "Enviar Mensagem",
          singleGroupSendMedia: "Enviar Mídia",
          singleGroupSendVoice: "Enviar Áudio",
          bulkGroupChangeTitle: "Mudar Título",
          bulkGroupChangeDescription: "Mudar Descrição",
          bulkGroupChangePicture: "Mudar Imagem",
          bulkGroupSetAdminOnly: "Permissão",
          bulkGroupPromote: "Promover Admin",
          bulkGroupDemote: "Demover Admin",
          bulkGroupAdd: "Adicionar Participante",
          bulkGroupRemove: "Remover Participante",
          bulkGroupSendMessage: "Enviar Mensagem",
          bulkGroupSendMedia: "Enviar Mídia",
          bulkGroupSendVoice: "Enviar Áudio"
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      ghostList: {
        title: "Marcação Fantasma",
        table: {
          shortcut: "Atalho",
          message: "Mensagem",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Marcação Fantasma",
          delete: "Deletar Marcação Fantasma",
        },
        toasts: {
          deleted: "Marcação Fantasma excluída com sucesso.",
        },
        popup: {
          confirm: "Tem certeza de que deseja excluir todas as marcações fantasma?",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Marcação Fantasma: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      wordList: {
        title: "Palavras Proibidas",
        table: {
          shortcut: "Id do Grupo",
          message: "Palavra Proibida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Palavra Proibida",
          delete: "Deletar Palavra Proibida",
        },
        toasts: {
          deleted: "Palavra Proibida excluída com sucesso.",
        },
        popup: {
          confirm: "Tem certeza de que deseja excluir todas as palavras proibidas?",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Palavra Proibida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      banList: {
        title: "Números Banidos",
        table: {
          shortcut: "Id do Grupo",
          message: "Número Banido",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Número Banido",
          delete: "Deletar Número Banido",
        },
        toasts: {
          deleted: "Número Banido excluído com sucesso.",
        },
        popup: {
          confirm: "Tem certeza de que deseja excluir todos os número banidos?",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir este Número Banido: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      groupLinkList: {
        title: "Grupos",
        table: {
          groupId: "Id do Grupo",
          name: "Nome",
          link: "Link",
          participants: "Participantes",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Grupo",
          delete: "Deletar Grupo",
        },
        toasts: {
          deleted: "Grupo excluído com sucesso.",
        },
        popup: {
          confirm: "Tem certeza de que deseja excluir todos os grupos?",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir este Grupo: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      greetings: {
        title: "Mensagem de Saudação",
        table: {
          shortcut: "Id do Grupo",
          message: "Mensagem",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Mensagem de Saudação",
          delete: "Deletar Mensagem de Saudação",
        },
        toasts: {
          deleted: "Mensagem de Saudação excluída com sucesso.",
        },
        popup: {
          confirm: "Tem certeza de que deseja excluir todas as mensagens de saudação?",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Mensagem de Saudação: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      farewell: {
        title: "Mensagem de Despedida",
        table: {
          shortcut: "Id do Grupo",
          message: "Mensagem",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Mensagem de Despedida",
          delete: "Deletar Mensagem de Despedida",
        },
        toasts: {
          deleted: "Mensagem de Despedida excluída com sucesso.",
        },
        popup: {
          confirm: "Tem certeza de que deseja excluir todas as mensagens de despedida?",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Mensagem de Despedida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      participants: {
        title: "Leads",
        table: {
          shortcut: "Id do Grupo",
          name: "Usuário",
          user: "ID",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Leads",
          delete: "Deletar Leads",
        },
        toasts: {
          deleted: "Leads excluído com sucesso.",
        },
        popup: {
          confirm: "Tem certeza de que deseja excluir todos os Leads?",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir este Leads: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      settings: {
        success: "Ação executada com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou tecle ''/'' para utilizar as respostas rápidas cadastrada",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      commonLabels: {
        whatsAppID: "ID do WhatsApp",
        groupId: "ID do Grupo",
        number: "Número",
        title: "Título",
        description: "Descrição",
        picture: "URL da Imagem",
        onOff: "Ligar/Desligar",
        participantsList: "Participantes (separados por vírgula)",
        message: "Mensagem",
        media: "URL da Mídia/Arquivo",
        caption: "Título da Mídia/Arquivo",
        action: "Executar",
        copyData: "Copiar Dados",
        copyComma: "Copiar (Vírgula)",
        copyLine: "Copiar (Linha)",
        excelExport: "Exportar para Excel"
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de uma fila.",
      },
    },
  },
};

export { messages };
