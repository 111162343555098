import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import image from "../../assets/icon.png";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundColor: "#f4f4f4",
      borderRadius: 15,
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#333",
      color: "white",
      padding: theme.spacing(3),
      textAlign: "center",
      borderRadius: 10
    },
    button: {
      backgroundColor: "#4caf50",
      color: "white",
      padding: theme.spacing(1, 2),
      fontSize: 16,
      cursor: "pointer",
      borderRadius: 5,
      marginTop: theme.spacing(2),
    },
    image: {
      maxWidth: "100%",
      height: "200px",
      borderRadius: 8,
      marginTop: theme.spacing(2),
    },
}));
  

function App() {
  const [searchParam] = useState("");
  const [groupLink, setGroupLink] = useState("");
  const [groupName, setGroupName] = useState("");
  const classes = useStyles();

  const handleClick = () => {
    if (groupLink) {
      window.open(groupLink, "_blank");
    } else {
      alert("Nenhum link disponível!");
    }
  };

  const fetchGroupLinkList = useCallback(async () => {
    try {
      const { data } = await api.get("/groupLinkListAll/", {
        params: { searchParam },
      });
  
      if (data.groupLinkList && data.groupLinkList.length > 0) {
        const sortedGroups = data.groupLinkList.sort((a, b) => a.id - b.id);
        let selectedGroup;
        for (const group of sortedGroups) {
          const participants = parseInt(group.participants, 10);
          if (participants <= 1020) {
            selectedGroup = group;
            break;
          }
        }
        if (!selectedGroup) {
          selectedGroup = sortedGroups[1];
        }
        setGroupLink(selectedGroup.link);
        setGroupName(selectedGroup.name);
      } else {
        console.log("Nenhum grupo encontrado.");
      }
    } catch (err) {
      toastError(err);
    }
  }, [searchParam]);
  

  useEffect(() => {
    fetchGroupLinkList();
  }, [fetchGroupLinkList]);

  return (
    <Paper className={classes.root}>
        <div className={classes.header}>
        <Typography variant="h4">{groupName}</Typography>        
        <Button className={classes.button} onClick={handleClick}>
            Entrar no Grupo
        </Button>
        {groupLink && <img className={classes.image} src={image} alt="Imagem" />}
        </div>
    </Paper>
  );
}

export default App;
